import React, {useState} from 'react';
import BikeProject from '../../../images/bikeProject.svg';
import Arrow from '../../../images/description-arrow.svg';
import Roommiez from '../../../images/roommiez.svg';
import Website from '../../../images/website.svg';
import RedirectArrow from '../../../images/redirect-arrow.svg';
import RedirectArrowHover from '../../../images/redirect-arrow-2.svg';
import { navigate } from 'gatsby';

import { ProjectWrapper, Project, Title, ProjectImage, Description, DescriptionText, ProjectImageWrapper } from './projects.style';

export default function Projects() {
  const [arrow, setArrow] = useState(RedirectArrow);
  return (
    <ProjectWrapper>
      <Project>
        <div className='project-image-wrapper' onClick={()=>navigate('/dont-quit-bicycle')}>
          <img className='project-image' src={BikeProject} style={{marginBottom: "0px", marginLeft:'75px', bottom: "55px", position: "relative"}}/>
          <div className='hide'>
            Don’t Quit Bicycle Maintenance
            <img onMouseOut={()=>setArrow(RedirectArrow)} onMouseOver={()=>setArrow(RedirectArrowHover)} className='redirect-arrow' style={{marginTop:'10px'}} src={arrow} />
          </div>
        </div>
        <Description>
          <img loading="lazy" src={Arrow} />
          <DescriptionText>
            A bicycling service website that offers information and resources for bicyclists who will have access to a variety of tools to repair and maintain their bicycles as well as experts to help them by appointment.          </DescriptionText>
        </Description>
      </Project>
      <Project>
        <div className='project-image-wrapper' onClick={()=>navigate('/roomiez')}>
          <img className='project-image' src={Roommiez} style={{ marginBottom: '0px', bottom: "45px", position: "relative" }} />
          <div className='hide'>
            Roomiez
            <img onMouseOut={()=>setArrow(RedirectArrow)} onMouseOver={()=>setArrow(RedirectArrowHover)} className='redirect-arrow' style={{marginTop:'10px'}} src={arrow} />
          </div>
        </div>
        <Description>
          <img loading="lazy" src={Arrow} />
          <DescriptionText>
            A roommates mobile app to help individuals living in shared spaces keep track of chores, lending items, and payments. 
          </DescriptionText>
        </Description>
      </Project>
      <Project style={{marginBottom: "150px"}}>
       <div className='project-image-wrapper' onClick={()=>window.open('https://www.figma.com/file/9ZCZV5CSuT4JvOZ7vgI7iy/My-website?type=design&node-id=781%3A7289&mode=design&t=cratq7L1vDsqrnmM-1', "_blank")}>
          <img className='project-image' src={Website} style={{ marginBottom: '0px', width: "135vh", bottom: "35px", position: "relative" }} />
          <div className='hide'>
            jaymorales-uxdesigner.com
            <img onMouseOut={()=>setArrow(RedirectArrow)} onMouseOver={()=>setArrow(RedirectArrowHover)} className='redirect-arrow' style={{marginTop:'10px'}} src={arrow} />
          </div>
        </div>
        <Description>
          <img loading="lazy" src={Arrow} />
          <DescriptionText>
            I took on the challenge of designing and creating my personal website and portfolio utilizing Figma.
          </DescriptionText>
        </Description>
      </Project>
    </ProjectWrapper>
  );
}
