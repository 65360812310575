import styled from 'styled-components';


export const Flex = styled.div`
    display: flex;
`;

export const Title = styled.div`
  text-align: center;
  font-size: 60px;
  color: #F8EDDD;
  margin-bottom: 40px;
  font-family: Playfair Display;
  font-weight: 900;
`;

export const AboutMeImage = styled.img`
  text-align: center;
  width: 47vh;
  border-radius: 30px;
  height: auto;
`;

export const Description = styled.div`
  width: 67vh;
  margin-left: 30px;
  color: white;
  font-family: Playfair Display;
  line-height: 170%;
  font-size: 20px;
`;

export const DescriptionText = styled.div`
  font-family: Playfair Display;
  font-size: 22px;
  color: white;
  margin-left: 40px;
  text-align: left;
`;

export const AboutMeWrapper = styled.div`
    padding: 15px 150px 0 150px;
    justify-content: center;
    background-color: #030101;
    .projectsInfo{
      height: 206px;
      .projectIcon{
        width: 145px;
        cursor:pointer;
        margin: 10px;
      }
      .projectIcon:hover{
        opacity: 0.80;
      }
      @media (max-width: 920px) {
        display: contents;
      },
    }
  `;
