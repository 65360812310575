import styled from 'styled-components';
import HomeBackgroundImage from '../../../images/home-background.svg';

export const HomeWrapper = styled.div`
  background-size: cover;
  background-position: center;
  background-color: #202020;
  @media (max-width: 640px) {
    background-size: 100%;
    background-position: center;
  }
  @media (max-width: 1285px) {
    padding-bottom: 100px;
  }
  .resume-btn {
      border: none;
      background-color: #9D9D9D;
      color: white;
      height: 64px;
      width: 400px;
      border-radius: 4px;
      cursor: pointer;
      @media (max-width: 1285px) {
        justify-self: center;
        margin-bottom: 30px;
      }
  }
  .resume-btn:active {
    opacity: 0.90;
}
  .name {
    font-family: Sansita One;
    font-size: 64px;
    font-weight: bolder;
    color: #F8EDDD;
  }
  .home-container {
    display: flex;
    justify-content: space-between;
    padding: 150px;
    @media (max-width: 1285px) {
      display: initial;
      text-align: center;
    }
  }
  .home-container-2 { 
    align-self: center;
    margin-right: 100px;
    @media (max-width: 1285px) {
      margin-right: 0px;
    }
  }
  .container {
    @media (max-width: 1285px) {
      margin-top: 30px;
    }
    @media (max-width: 400px) {
      padding-top: 15%;
      margin-top: 35px;
    }
    .bio{
      font-size: 18px;
      font-weight: 600;
      color: #8dae84;
      background-color: white;
      padding: 10px;
      border-radius: 5%;
      opacity: 90%;
    }
  }
`;

export const Title = styled.a`
  cursor: pointer;
  position: relative;
  height: auto;
  width: fit-content;
  font-family: Manrope;
  font-size: 20px;
  font-weight: bolder;
  background-image: linear-gradient(to bottom, #F8EDDD 50%, black 90%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  align-self: center;
`;
