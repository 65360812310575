import styled, {keyframes} from 'styled-components';


export const Flex = styled.div`
    display: flex;
`;

export const Title = styled.div`
  text-align: center;
  font-size: 48px;
  margin-bottom: 40px;
  font-family: Manrope;
  font-weight: bolder;
  color: rgba(248, 237, 221, 0.867);
`;

const fadeIn = keyframes`
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
`;

export const PhotographyImage = styled.img`
  text-align: center;
  width: max-content;
  width: 100%;
  border-radius: 12px;

`;

export const Description = styled.div`
  margin: 0 265px;
  text-align: center;
  display: flex;
`;

export const DescriptionText = styled.div`
  font-family: Manrope;
  font-size: 22px;
  color: white;
  margin-left: 40px;
  text-align: left;
`;

export const PhotographyWrapper = styled.div`
    padding: 15px 150px 130px 150px;
    justify-content: center;
    background-color: #202020;
    .projectsInfo{
      height: 206px;
      .projectIcon{
        width: 145px;
        cursor:pointer;
        margin: 10px;
      }
      .projectIcon:hover{
        opacity: 0.80;
      }
      @media (max-width: 920px) {
        display: contents;
      },
    }
  `;
