import React from 'react';
import Image from '../../../images/about-me-image.jpg';

import { AboutMeWrapper, Title, AboutMeImage, Description, DescriptionText } from './aboutme.style';

export default function AboutMe() {
  return (
    <AboutMeWrapper>
      <Title>About Me</Title>
      <div style={{justifyContent: 'center', display: 'flex'}}>
        <AboutMeImage src={Image} />
        <Description>
          Hello!
          <br/>
          <br/>
          I’m John Morales—an emphatic UX/UI designer and an avid freelance photographer based in San Diego, CA.
          <br/>
          <br/>
          With a background in photography and a passion for creating user-friendly & human-centered visual designs, I am able to approach projects from both a creative and a technical perspective. My goal is to deliver unique and engaging content that resonates with my clients. As a keen learner seeking new ways to improve my skills and talents, I am eager to make a positive impact on the projects I work on.
        </Description>
      </div>
    </AboutMeWrapper>
  );
}
