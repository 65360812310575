import styled from 'styled-components';


export const Flex = styled.div`
    display: flex;
`;

export const Title = styled.div`
  text-align: center;
  font-size: 60px;
  color: #F8EDDD;
  margin-bottom: 55px;
  font-family: Manrope;
  font-weight: 900;
`;

export const Project = styled.div`
  border-radius: 42px;
  background-color: #3E3E3E;
  margin-bottom: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @keyframes fadeIn {
    0% { opacity: 0.3; }
    100% { opacity: 1; }
  }
  @keyframes fadeOut {
    0% { opacity: 1; }
    100% { opacity: 0.3; }
  }
  .project-image {
    margin-bottom: 45px;
    width: 165vh;
    &:hover {
      animation: fadeOut 1s;
      opacity: 0.3;
    }
  }
  .hide {
    display: none;
    justify-items: center;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1; 
    color: white;
    font-size: 32px;
    font-family: Manrope;
  }
  .project-image-wrapper{
    cursor: pointer;
    position: relative;
    width: fit-content;
  }
  }
  .project-image-wrapper:hover{
    .hide {
      animation: fadeIn 2s;
      display: grid;
    }
    .project-image {
      animation: fadeOut 1s;
      opacity: 0.3;
    }
  }
  @media (max-width: 1380px) {
    background-color: transparent;
  },
`;

export const Description = styled.div`
  margin: 0 265px 0px 265px;
  padding-bottom: 30px;
  text-align: center;
  display: flex;
  width: 660px;
`;

export const DescriptionText = styled.div`
  font-family: Manrope;
  font-size: 22px;
  color: white;
  margin-left: 40px;
  text-align: left;
  position: relative;
`;

export const ProjectWrapper = styled.div`
    padding: 36px 150px 50px 150px;
    justify-content: center;
    background-color: #202020;
    .projectsInfo{
      height: 206px;
      .projectIcon{
        width: 145px;
        cursor:pointer;
        margin: 10px;
      }
      .projectIcon:hover{
        opacity: 0.80;
      }
      @media (max-width: 920px) {
        display: contents;
      },
    }
  `;
