import React, { useState } from "react";
import Container from "@material-ui/core/Container";
import { HomeWrapper, Title } from "./home.style";
import HomeBackgroundImage from "../../../images/home-background.svg";
import Headshot from "../../../images/headshot.svg";
import Logo from "../../../images/logo.svg";
import Bio from "../../../images/bio.svg";

export default function Home() {
  return (
    <HomeWrapper>
      <div
        style={{
          height: "80px",
          display: "flex",
          padding: "20px 60px",
          backgroundColor: "rgba(50, 50, 50, 0.7)",
        }}
      >
        <Title>
          <img loading="lazy" src={Logo} />
        </Title>
      </div>
      <div className="home-container">
        <div className="home-container-2">
          <div className="container">
            <font
              className="name"
              style={{
                color: "white",
                backgroundImage: "none",
                marginRight: "20px",
                fontFamily: "Manrope",
              }}
            >
              Hi, I’m
            </font>
            <font className="name"><i>John Morales</i></font>
          </div>
          <br/>
          <div style={{ display: "grid" }}>
            <font
              className="name"
              style={{
                color: "white",
                backgroundImage: "none",
                marginRight: "10px",
                fontFamily: "Manrope",
                fontSize: "24px",
                marginBottom: "130px",
              }}
            >
            <img loading="lazy" src={Bio} />
            <br />
              A passionate UI/UX Designer on a journey of crafting digital experiences that evolve with every challenge and discovery. Also a lover of capturing timeless memories as a freelance photographer.
            </font>
            <button
              className="resume-btn"
              onClick={()=>window.open("https://drive.google.com/file/d/1TAsG1GGcv9ny1YugdZVkOB3nxBe6VgZB/view", "_blank")}
            >
              <font style={{fontFamily: "Manrope", fontSize: "20px", fontWeight: 500}}>Resume</font>
            </button>
          </div>
        </div>
        <div style={{ textAlign: "center", top: "10%", position: "relative" }}>
          <img loading="lazy" src={Headshot} />
        </div>
      </div>
    </HomeWrapper>
  );
}
