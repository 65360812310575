import React, {useState, useEffect} from 'react';
import Photo1 from '../../../images/photography/1.svg';
import Photo2 from '../../../images/photography/2.svg';
import Photo3 from '../../../images/photography/3.svg';
import Photo4 from '../../../images/photography/4.svg';
import Photo5 from '../../../images/photography/5.svg';
import Photo6 from '../../../images/photography/6.svg';
import Photo7 from '../../../images/photography/7.svg';

import { PhotographyWrapper, Title, PhotographyImage, Description, DescriptionText } from './photography.style';

export default function Photography() {
    const [currentPhoto, setCurrentPhoto] = useState(1)
    const photoSources = [Photo1, Photo2, Photo3, Photo4, Photo5, Photo6, Photo7];

    useEffect(()=>{
      setTimeout(()=>{
        if(currentPhoto === 6){
          setCurrentPhoto(1)
        }else{
          setCurrentPhoto(prev=> prev + 1)
        }
      }, 3000)
    }, [currentPhoto])

  return (
    <PhotographyWrapper>
      <Title>Photography</Title>
      <div style={{textAlign: 'center', width: "100%", cursor: "pointer"}} onClick={()=>window.open("https://jaymorales.pic-time.com/portfolio", "_blank")}>
        {<PhotographyImage src={photoSources[currentPhoto - 1]} />}
      </div>
    </PhotographyWrapper>
  );
}
