import React from 'react';
import NavBar from '../components/App/NavBar';
import Projects from '../components/App/Projects';
import Home from '../components/App/Home';
import Photography from '../components/App/Photography';
import AboutMe from '../components/App/AboutMe';
import Footer from '../components/App/Footer';
import { Helmet } from 'react-helmet';
import { createGlobalStyle } from 'styled-components';
import "@fontsource/sansita/400.css";
import "@fontsource/poppins";
import "@fontsource/poppins/700.css";
import "@fontsource/manrope"; // Defaults to weight 400

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;
export default function Page(props) {
  return (
    <div>
      <GlobalStyle />
      <Helmet>
        <meta charSet='utf-8' />
        <meta property='og:title' content='John Morales' />
        <meta name='author' content='John Morales' />
        <title>John Morales</title>
      </Helmet>
      <Home />
      <Projects />
      <Photography />
      <Footer />
    </div>
  );
}
